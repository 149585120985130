import React from 'react'
import styled from 'styled-components'
import Container from '@bootstrap-styled/v4/lib/Container'
import Row from '@bootstrap-styled/v4/lib/Row'
import Col from '@bootstrap-styled/v4/lib/Col'
import Nav from '@bootstrap-styled/v4/lib/Nav'
import NavItem from '@bootstrap-styled/v4/lib/Nav/NavItem'
import NL from '@bootstrap-styled/v4/lib/Nav/NavLink'
import theme from '../Theme'
import Link from 'next/link'
import LowerFooter from './LowerFooter'

const NavLink = styled(NL)`
  &.nav-link {
    padding: .5em 0;
    color: ${theme['$headings-color']};
    &:hover {
      color: ${theme['$body-color']};
    }
  }
`

const StyledFooter = styled.footer`
  border-top: 1px solid #e8e8e8;
  margin-top: ${props => props.noMargin ? '0' : '48px'};
  padding-top: 64px;
  .navTitle {
    padding: .5em 0 1em;
    color: ${theme['$headings-color']};
    font-weight: 500;
  }
`

function Footer ({ noMargin = false, ...props }) {
  return (
    <StyledFooter noMargin={noMargin} {...props}>
      <Container>
        <Row>
          <Col lg={3}>
            <div className={'navTitle'}>Company</div>
            <Nav vertical>
              <NavItem><Link href={'/info/about-us'} passHref>
                  <NavLink>About us</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/info/how-it-works'} passHref>
                <NavLink>How it works</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/info/contact-us'} passHref>
                <NavLink>Contact us</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/blog'} passHref>
                <NavLink>Blog</NavLink>
              </Link></NavItem>
            </Nav>
          </Col>
          <Col lg={3}>
            <div className={'navTitle'}>For Patients</div>
            <Nav vertical>
              <NavItem><Link href={'/services/sports-physiotherapy'} passHref>
                <NavLink>Sports Physiotherapy</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/services/womens-health-physiotherapy'} passHref>
                <NavLink>Women’s Health Physiotherapy</NavLink>
              </Link></NavItem>
              {/*<NavItem><Link href={'/'} passHref>*/}
              {/*  <NavLink>Physio near me</NavLink>*/}
              {/*</Link></NavItem>*/}
              <NavItem><Link href={'/physiotherapy'} passHref>
                <NavLink>Physiotherapy</NavLink>
              </Link></NavItem>
              {/*<NavItem><Link href={'/'} passHref>*/}
              {/*  <NavLink>Blog</NavLink>*/}
              {/*</Link></NavItem>*/}
              <NavItem><Link href={'/'} passHref>
                <NavLink>FAQs</NavLink>
              </Link></NavItem>
            </Nav>
          </Col>
          <Col lg={3}>
            <div className={'navTitle'}>For Clinics</div>
            <Nav vertical>
              <NavItem>
                <NavLink target="_blank" href="https://localphysionetwork.typeform.com/to/Em0forsy">Register clinic</NavLink>
              </NavItem>
              <NavItem><Link href={'/info/how-it-works'} passHref>
                <NavLink>How it works</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/'} passHref>
                <NavLink>FAQs</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/info/media'} passHref>
                <NavLink>Media</NavLink>
              </Link></NavItem>
            </Nav>
          </Col>
          <Col lg={3}>
            <div className={'navTitle'}>Popular searches</div>
            <Nav vertical>
              <NavItem><Link href={'/physiotherapy/[suburbId]'} as={`/physiotherapy/sydney`} passHref>
                <NavLink>Physio in Sydney</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/physiotherapy/[suburbId]'} as={`/physiotherapy/melbourne`} passHref>
                <NavLink>Physio in Melbourne</NavLink>
              </Link></NavItem>
              <NavItem><Link href={'/physiotherapy/[suburbId]'} as={`/physiotherapy/brisbane`} passHref>
                <NavLink>Physio in Brisbane</NavLink>
              </Link></NavItem>
              <NavItem>
                <NavLink href={'https://physionearme.co'} target={'_blank'}>Physio near me</NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <LowerFooter/>
      </Container>
    </StyledFooter>
  )
}

export default Footer
