import React from 'react'
import styled from 'styled-components'
import A from '@bootstrap-styled/v4/lib/A'
import Row from '@bootstrap-styled/v4/lib/Row'
import Col from '@bootstrap-styled/v4/lib/Col'
import Small from '@bootstrap-styled/v4/lib/Small'
import theme from '../Theme'
import Link from 'next/link'
import Nav from '@bootstrap-styled/v4/lib/Nav'
import NavItem from '@bootstrap-styled/v4/lib/Nav/NavItem'
import NavLink from '@bootstrap-styled/v4/lib/Nav/NavLink'

const Copyright = styled(Small)`
  ${A} {
    color: ${theme['$body-color']};
  }
`

const StyledFooter = styled(Row)`
  border-top: 1px solid ${(props) => props.dark ? '#313131' : '#e8e8e8' };
  margin-top: 64px;
  padding: 24px 0;
  align-items: center;
  justify-items: between;
  
  
  .heap-col {
    width: 100%;
    text-align: center;
    @media(min-width: 992px) {
      width: 108px,
      align-self: end,
      flex-grow: 0;
    }
  }
  .social-col .nav {
    @media(min-width: 992px) {
      justify-content: flex-end
    }
  }
`

const SocialItem = styled(NavLink)`
  .nav-link {
    svg {
      fill: #999;
    }
  }
  .nav-link:hover {
    svg {
      fill: #333;
    }
  }
`

import MediumLogo from '../../assets/svg/medium-logo.svg'
import LinkedinLogo from '../../assets/svg/linkedin-logo.svg'
import FacebookLogo from '../../assets/svg/facebook-logo.svg'
import TwitterLogo from '../../assets/svg/twitter-logo.svg'
import InstagramLogo from '../../assets/svg/instagram-logo.svg'
import CrunchbaseLogo from '../../assets/svg/crunchbase-logo.svg'

const links = {
  crunchbase: 'https://www.crunchbase.com/organization/local-physio-network',
  linkedin: 'https://www.linkedin.com/company/local-physio-network',
  facebook: 'https://www.facebook.com/localphysionetwork',
  twitter: 'https://twitter.com/localphysio',
  medium: 'https://medium.com/local-physio-network',
}

function LowerFooter ({ dark }) {

  const privacyPolicy = <Link href={'/legal/privacy-policy'} passHref>
    <A>Privacy Policy</A>
  </Link>

  const termsAndConditions = <Link href={'/legal/terms-and-conditions'} passHref>
    <A>Terms & Conditions</A>
  </Link>

  let heapImg
  if (process.env === 'production') heapImg = `//heapanalytics.com/img/${dark ? 'badge' : 'badgeLight'}.png`
  else heapImg = dark ? require('../../assets/images/heapBadgeDark.png') : require('../../assets/images/heapBadgeLight.png')

  const Heap = <a href="https://heap.io/?utm_source=badge" rel="nofollow">
    <img width={108} height={41} src={heapImg} alt="Heap | Mobile and Web Analytics" />
  </a>

  return (
    <StyledFooter dark={dark}>
      <Col lg={7}>
        <Copyright>Copyright © 2020 – Local Physio Network. All rights reserved. – {privacyPolicy} – {termsAndConditions}</Copyright>
      </Col>
      <Col className={'social-col py-3 py-sm-0'}>
        <Nav>
          { links.medium && <NavItem>
            <SocialItem href={links.medium}><MediumLogo width={16} height={16}/></SocialItem>
          </NavItem> }
          { links.linkedin && <NavItem>
            <SocialItem href={links.linkedin}><LinkedinLogo width={16} height={16}/></SocialItem>
          </NavItem> }
          { links.facebook && <NavItem>
            <SocialItem href={links.facebook}><FacebookLogo width={16} height={16}/></SocialItem>
          </NavItem> }
          { links.twitter && <NavItem>
            <SocialItem href={links.twitter}><TwitterLogo width={16} height={16}/></SocialItem>
          </NavItem> }
          { links.instagram && <NavItem>
            <SocialItem href={links.instagram}><InstagramLogo width={16} height={16}/></SocialItem>
          </NavItem> }
          { links.crunchbase && <NavItem>
            <SocialItem href={links.crunchbase}><CrunchbaseLogo width={16} height={16}/></SocialItem>
          </NavItem> }
        </Nav>
      </Col>
      <Col className={'heap-col'}>
        {Heap}
      </Col>
    </StyledFooter>
  )
}

export default LowerFooter
